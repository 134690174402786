import React from 'react';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import GatsbyImage from 'gatsby-image';
import Section from '@components/theme/Section';
import { getBlogUrl } from '../lib/urls';

export default function FeaturedPost({ children, featured, language, divider, link, parent }) {
  const title = (
    <h1>{featured.properties.title}</h1>
  );
  const header = link ? (
    <Link to={getBlogUrl({ parent, page: featured, language })}>
      {title}
    </Link>
  ) : title;
  return (
    <BackgroundImage
      fluid={[
        'linear-gradient( rgba(0,0,0,0.6), rgba(0,0,0,0.6))',
        featured.properties.featuredImage.localFile.sharp.fluid
      ]}
    >
      {children}
      <hr className="border-light m-0 opacity-60" />
      <Section className="text-light overlay" dividerBottom={divider}>
        <div className="container pt-6">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              {header}
              <div className="d-flex align-items-center">
                {featured.blogPost.author.teamMember.photo && (
                  <GatsbyImage
                    alt="Image"
                    className="avatar mr-2"
                    fluid={featured.blogPost.author.teamMember.photo.localFile.sharp.fluid}
                  />
                )}
                <div>
                  <div>
                    <strong>{featured.blogPost.author.teamMember.name}</strong>
                  </div>
                  <div className="text-small text-muted">{featured.blogPost.publishDate}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </BackgroundImage>
  );
}

export const query = graphql`
  fragment FeaturedPost on WpBlogPost {
    properties {
      slug
      title
      featuredImage {
        localFile {
          sharp: childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    blogPost {
      author {
        ... on WpTeamMember {
          id
          teamMember {
            name
            photo {
              localFile {
                sharp: childImageSharp {
                  fluid(maxWidth: 40, maxHeight: 40) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      body
      publishDate
    }
  }
`;