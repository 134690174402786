import React from 'react';
import { graphql } from 'gatsby';
import Header from '@components/Header';
import Page from '@components/Page';
import Footer from '@components/Footer';
import BlogPosts from '@components/BlogPosts';
import FeaturedPost from '@components/FeaturedPost';

export default function BlogTemplate(data) {
  const {
    data: {
      page,
      posts,
      featured,
      authors
    },
    pageContext: { language, refs, layouts, configuration }
  } = data;
  return (
    <Page>
      <FeaturedPost
        featured={featured}
        language={language}
        divider={{ variant: 1, backgroundClass: 'bg-primary' }}
        parent={page}
        link
      >
        <Header
          language={language}
          refs={refs}
          layouts={layouts}
        />
      </FeaturedPost>
      <BlogPosts
        parent={page}
        posts={posts}
        authors={authors}
        language={language}
      />
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Page>
  );
}

export const query = graphql`
  query($id: String, $blogPostId: String, $lang: WpLanguageCodeEnum) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
    posts: allWpBlogPost(filter: {language: {code: {eq: $lang}}, id: {ne: $blogPostId}}) {
      nodes {
        ...BlogPosts
      }
    }
    featured: wpBlogPost(id: {eq: $blogPostId}) {
      ...FeaturedPost
    }
    authors: allWpTeamMember(filter: {language: {code: {eq: $lang}}}) {
      nodes {
        id
        teamMember {
          name
          photo {
            localFile {
              sharp: childImageSharp {
                fluid(maxWidth: 40, maxHeight: 40) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;