import React from 'react';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import GatsbyImage from 'gatsby-image';
import moment from 'moment';
import { getBlogUrl } from '@lib/urls';

function PostCard ({ parent, post, language }) {
  let postLink = getBlogUrl({ parent, page: post, language });
  return (
    <div className="d-flex col-xl-4 col-md-6 col-sm-12">
      <div className="card w-100 border-0 shadow">
        <Link to={postLink}>
          <BackgroundImage
            alt="Image"
            className="card-img-top"
            style={{ height: '200px' }}
            fluid={post.properties.featuredImage.localFile.sharp.fluid}
          />
        </Link>
        <div className="card-body d-flex flex-column">
          <div className="d-flex justify-content-between mb-3">
            <div className="text-small d-flex">
              <div className="badge bg-primary-alt text-primary">
                <Link to={postLink}>{post.categories.nodes && post.categories.nodes[0].name}</Link>
              </div>
            </div>
            <div className="text-small d-flex">
              <span className="text-muted">{post.blogPost.publishDate}</span>
            </div>
          </div>
          <Link to={postLink}>
            <h4>{post.properties.title}</h4>
          </Link>
          <p className="flex-grow-1">
            {post.blogPost.excerpt}
          </p>
          <div className="d-flex align-items-center mt-3">
            {post.blogPost.author.teamMember.photo && (
              <GatsbyImage
                alt="Image"
                className="avatar avatar-sm shadow"
                fluid={post.blogPost.author.teamMember.photo.localFile.sharp.fluid}
              />
            )}
            <div className="ml-1">
              <span className="text-small"> {post.blogPost.author.teamMember.name}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function BlogPosts({ parent, posts, language }) {
  const blogPosts = posts.nodes.sort((a, b) => moment(b.blogPost.publishDate, 'DD/MM/YYYY').toDate().getTime() - moment(a.blogPost.publishDate, 'DD/MM/YYYY').toDate().getTime());
  return (
    <div className="bg-primary pt-5 pb-5">
      <div className="container">
        <div className="row align-items-stretch">
          {blogPosts.map((post, i) => (
            <PostCard key={`post-${i}`} post={post} language={language} parent={parent} />
          ))}
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment BlogPosts on WpBlogPost {
    properties {
      slug
      title
      featuredImage {
        localFile {
          sharp: childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    categories {
      nodes {
        name
        slug
      }
    }
    blogPost {
      author {
        ... on WpTeamMember {
          id
          teamMember {
            name
            photo {
              localFile {
                sharp: childImageSharp {
                  fluid(maxWidth: 40, maxHeight: 40) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      body
      publishDate
      excerpt
    }
  }
`;